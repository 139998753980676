* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

.ico {
  width: 48px;
  height: 48px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.imageCentered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* requiro-ui component */

.MuiInputBase-root {
  background-color: #ffffff;
}

button.MuiButton-root {
  border-radius: 8px !important;
  box-shadow: none !important;
}

.MuiGrid-item.reset-padding {
  padding-left: 0 !important;
}
